import { Component } from '@angular/core';

@Component({
  selector: 'app-offcanvas-menu',
  standalone: true,
  imports: [],
  templateUrl: './offcanvas-menu.component.html',
  styleUrl: './offcanvas-menu.component.scss'
})
export class OffcanvasMenuComponent {

}
